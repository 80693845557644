import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-spokane-washington.png'
import image0 from "../../images/cities/scale-model-of-rotary-fountain-in-spokane-washington.png"
import image1 from "../../images/cities/scale-model-of-spokane-tribal-gathering-place-in-spokane-washington.png"
import image2 from "../../images/cities/scale-model-of-spokane-party-trolley-in-spokane-washington.png"
import image3 from "../../images/cities/scale-model-of-riverfront-park-in-spokane-washington.png"
import image4 from "../../images/cities/scale-model-of-escape-in-spokane-washington.png"
import image5 from "../../images/cities/scale-model-of-the-cathedral-of-st.-john-the-evangelist-in-spokane-washington.png"
import image6 from "../../images/cities/scale-model-of-barrister-winery-in-spokane-washington.png"
import image7 from "../../images/cities/scale-model-of-northwest-museum-of-arts-and-culture-in-spokane-washington.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Spokane'
            state='Washington'
            image={image}
            lat='47.6587802'
            lon='-117.42604649999998'
            attractions={ [{"name": "Rotary Fountain", "vicinity": "507 N Howard St, Spokane", "types": ["point_of_interest", "establishment"], "lat": 47.6605845, "lng": -117.4211272}, {"name": "Spokane Tribal Gathering Place", "vicinity": "353-367 N Post St, Spokane", "types": ["point_of_interest", "establishment"], "lat": 47.6608814, "lng": -117.42406}, {"name": "Spokane Party Trolley", "vicinity": "17 W Main Ave, Spokane", "types": ["point_of_interest", "establishment"], "lat": 47.658755, "lng": -117.4117789}, {"name": "Riverfront Park", "vicinity": "507 N Howard St, Spokane", "types": ["park", "point_of_interest", "establishment"], "lat": 47.6620637, "lng": -117.41912830000001}, {"name": "Escape", "vicinity": "7456 N Division St, Spokane", "types": ["point_of_interest", "establishment"], "lat": 47.725809, "lng": -117.41001870000002}, {"name": "The Cathedral of St. John the Evangelist", "vicinity": "127 E 12th Ave, Spokane", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 47.6450279, "lng": -117.40985949999998}, {"name": "Barrister Winery", "vicinity": "1213 West Railroad Avenue, Spokane", "types": ["food", "point_of_interest", "establishment"], "lat": 47.6552583, "lng": -117.43036080000002}, {"name": "Northwest Museum of Arts and Culture", "vicinity": "2316 W 1st Ave, Spokane", "types": ["museum", "cafe", "food", "store", "point_of_interest", "establishment"], "lat": 47.6569592, "lng": -117.445495}] }
            attractionImages={ {"Rotary Fountain":image0,"Spokane Tribal Gathering Place":image1,"Spokane Party Trolley":image2,"Riverfront Park":image3,"Escape":image4,"The Cathedral of St. John the Evangelist":image5,"Barrister Winery":image6,"Northwest Museum of Arts and Culture":image7,} }
       />);
  }
}